<template>
	<div>
		<div class="flex header">
			<h2>会员类型</h2>
			<el-button @click="addMemberType">新增会员类型</el-button>
		</div>
		<el-form ref="form" class="searchCriteria" label-suffix=":" :model="searchForm" label-width="100px">
			<div class="flex">
				<el-form-item label="会员类型">
					<el-input v-model="searchForm.name" placeholder="请输入会员类型名称"></el-input>
				</el-form-item>
				<el-form-item label="分类">
					<el-select style="margin-left:10px" v-model="searchForm.status" placeholder="请选择分类">
						<el-option label="全部" value=""></el-option>
						<el-option :label="item.label" :key="ind" v-for="(item,ind) in statusList" :value="item.val"></el-option>
					</el-select>
				</el-form-item>
				<div class=" btnGroup averageWid">
					<el-button @click="getData">查询</el-button>
					<el-button @click="reloadSearch">重置</el-button>
				</div>
			</div>
		</el-form>
		<div class="table">
			<el-table
					v-loading="loading"
					element-loading-text="拼命加载中"
					element-loading-spinner="el-icon-loading"
					element-loading-background="rgba(0, 0, 0, 0.8)"
					:data="tableData"
					:header-cell-style="{'background':'rgba(0, 103, 166, 0.3)','color': '#ffffff','fontWeight':'bold','textAlign':'center'}"
					height="calc(100vh - 326px)"
					style="width: 100%">
				<el-table-column
						prop="name" align="center" show-overflow-tooltip
						label="会员类型名称">
				</el-table-column>
				<el-table-column align="center"
												 label="分类"
												 width="100">
					<template slot-scope="scope">
						{{ scope.row.status == 0 ? "身份" : scope.row.status == 1 ? "区域" : scope.row.status == 2 ? "来源" : "-"}}
					</template>
				</el-table-column>
				<el-table-column
						prop="memberNum" align="center"
						label="会员人数">
				</el-table-column>
				<el-table-column align="center"
												 label="创建时间">
					<template slot-scope="scope">
						{{ scope.row.createTime|formatTimer }}
					</template>
				</el-table-column>
				<el-table-column align="center"
												 label="操作" width="180px">
					<template slot-scope="scope">
						<el-button class="update" @click="handleEdit(scope.$index, scope.row)">修改</el-button>
						<el-button class="update" @click="handleDel(scope.row.id)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
					class="pagination"
					@current-change="handleCurrentChange"
					:current-page="page.current"
					:page-size="page.size"
					layout="prev, pager, next, total"
					:total="page.total">
			</el-pagination>
		</div>
		<!--		新增编辑-->
		<el-dialog
				top="0" center class="commonDia updateMem" :close-on-click-modal="false"
				:visible.sync="memberType" :show-close="false"
				width="370px" :close-on-press-escape="false">
			<template #title>
				<h1 class="tit">{{ title }}会员类型</h1>
			</template>
			<el-form label-position="top" ref="form" class="rowsInfo" label-suffix="：" label-width="80px"
							 :model="formType">
				<el-form-item label="会员类型名称">
					<el-input v-model="formType.name" placeholder="请输入会员类型名称"></el-input>
				</el-form-item>
				<el-form-item label="分类">
					<el-select style="width:100%" v-model="formType.status" placeholder="请选择分类">
						<el-option :label="item.label" :key="ind" v-for="(item,ind) in statusList" :value="item.val"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="memberType=false">取 消</el-button>
				<el-button type="primary" @click="editType('form')">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { getMemberInfoTypeList, deleteMemberInfoType, addMemberInfoType, updateMemberInfoType } from "@/api/api";

export default {
	name: "memberType",
	data() {
		return {
			searchForm: {
				status: "",
				name: ""
			},
			tableData: [],
			page: {
				current: 1,
				size: 10,
				total: 0
			},
			statusList: [{
				val: 0,
				label: "身份"
			}, {
				val: 1,
				label: "区域"
			}, {
				val: 2,
				label: "来源"
			}],
			memberType: false,
			title: "新增",
			formType: {
				name: "",
				status: ""
			},
			loading:false
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		reloadSearch(){
			this.searchForm = {
				status: "",
				name: ""
			}
			this.page.current = 1
			this.getData()
		},
		getData() {
			this.loading = true
			let params = {
				page: this.page.current,
				size: this.page.size,
				key:this.searchForm.name,
				status:this.searchForm.status
			};
			getMemberInfoTypeList(JSON.stringify(params)).then(res => {
				this.tableData = res.data.rows;
				this.page.total = res.data.total;
				setTimeout(()=>{
					this.loading = false
				},200)
			}).catch(err => {
				console.log("err: " + err);
			});
		},
		handleCurrentChange(val) {
			this.page.current = val;
			this.getData();
		},
		// 新增
		addMemberType() {
			this.memberType = true;
			this.title = "新增";
			this.formType = {
				name: "",
				type: ""
			};
		},
		// 编辑
		handleEdit(index, row) {
			this.title = "编辑";
			this.memberType = true;
			this.formType = {
				id:row.id,
				name:row.name,
				status:row.status
			}
		},
		// 删除
		handleDel(id) {
			this.$confirm("此操作将永久删除数据，是否继续?", "提示", { type: "warning" })
					.then(() => { // 向服务端请求删除
						deleteMemberInfoType(id).then(() => {
							this.$message.success("删除成功");
							this.getData();
						}).catch(() => {
							this.$message.error("删除失败!");
						});
					}).catch(() => {
				this.$message.info("已取消操作!");
			});
		},
		editType(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					if (this.title === "新增") {
						addMemberInfoType(JSON.stringify(this.formType)).then((res) => {
							if (res.data.code==200){
								this.$message.success("新增成功");
							} else {
								this.$message.error(res.data.msg)
							}
						}).catch(() => {
							this.$message.error("新增失败");
						});
					} else {
						updateMemberInfoType(JSON.stringify(this.formType)).then((res) => {
							if (res.data.code==200){
								this.$message.success("修改成功");
							} else {
								this.$message.error(res.data.msg)
							}
						}).catch((err) => {
							this.$message.error(err.response.data.msg);
						});
					}
					setTimeout(() => {
						this.memberType = false;
						this.getData();
					}, 500);
				} else {
					return false;
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/table";
</style>
